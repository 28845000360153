<template>
  <div id="article-content">
    <div class="blue-background">
      <div class="article-page-header" v-if="article_data">
        <div class="grade-flag">
          <!--
        <p :class="switchClass(milestone.grade_key)">
          {{ milestone.grade_text_suffix }} Grade
        </p>
        -->
          <p :class="`${article_data.grade_text_suffix}`">
            {{ article_data.grade_title }}
          </p>
        </div>
        <h1 class="article-header">
          {{ article_data.article_title }}
        </h1>
        <div class="title-underline"></div>
        <div id="time-link-line">
          <!-- <p class="time-estimate">
          {{ article_data.article_content.time_estimate }}
        </p> -->
          <div class="share-link" id="share-link" @click="copyText">
            <img src="../assets/link-icon.png" alt="" />
            <div id="linkText">Copy link</div>
          </div>
        </div>
        <div id="copy-url" style="display: none">
          <div class="copy-url-text">Copy this URL to share:</div>
          <label @click="closeShare" class="close-share">
            <img
              class="close-share-icon"
              src="../assets/close-icon.svg"
              alt="close share link icon"
            />
          </label>
          <input type="text" id="currentURL" />
          <button @click="copyText">Copy</button>
        </div>
      </div>
      <div class="img-div" v-if="article_data.article_hero_image">
        <img
          :src="article_data.article_hero_image"
          alt="Article hero image"
          class="article-img"
        />
      </div>
      <div v-else-if="article_data.category_hero_image">
         <img
          :src="article_data.category_hero_image"
          alt="Category hero image"
          class="article-img"
        />
      </div>
      <div v-else>
        <img
          src="../assets/Article_forest.jpg"
          alt="Article hero image"
          class="article-img"
        />
      </div>
      <div v-if="article_data.article_external">
        <div class="article-description">
          <div
            class="dynamic-content"
            v-html="article_data.article_external.article_description"
          />
        </div>
        <b-button
          :href="`${article_data.article_external.article_external_url}`"
          class="learn-more-btn"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ lang_stanza.learn_more }}
        </b-button>
      </div>
    </div>

    <!-- <div v-if="article_data.article_external">
      <br />----- <br /><strong>Article - External</strong> <br />article_title:
      {{ article_data.article_title }} <br />article_key:
      {{ article_data.article_key }} <br />article_h_image:
      {{ article_data.article_h_image }} <br />article_description:
      <div
        class="dynamic-content"
        v-html="article_data.article_external.article_description"
      />
      <br />article_external_url:
      {{ article_data.article_external.article_external_url }}
      <br />article_external_url_text:
      {{ article_data.article_external.article_external_url }}
      <br />grade_key: {{ article_data.grade_key }} <br />grade_key_suffix:
      {{ article_data.grade_key_suffix }} <br />grade_text:
      {{ article_data.grade_text }} <br />grade_text_suffix:
      {{ article_data.grade_text_suffix }} <br />grade_title:
      {{ article_data.grade_title }} <br />pathway_key:
      {{ article_data.pathway_key }} <br />pathway_title:
      {{ article_data.pathway_title }} <br />grade_pathway_title:
      {{ article_data.grade_pathway_title }} <br />milestone_key:
      {{ article_data.milestone_key }} <br />milestone_title:
      {{ article_data.milestone_title }} <br />strategy_key:
      {{ article_data.strategy_key }} <br />strategy_title:
      {{ article_data.strategy_title }}
    </div> -->
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { router } from "../_helpers";
export default {
  name: "ArticleExternal",
  data() {
    return {
      title: "",
      description: "",
      errors: "",
      article: {},
      lang_info: {},
      lang_stanza_key: "article",
      lang_stanza: {},
    };
  },
  async created() {
    this.lang_info = await this.getLangInfo();
    this.lang_stanza = await this.getLangStanza({
      lang_code: this.lang_info["lang_code"],
      lang_stanza_key: this.lang_stanza_key,
    });

    document.body.style.backgroundColor = "#FFFFFF";
    document.title = this.article_data.article_title;
    try {
      // let data = {};
      // data.article_key = this.$route.params.article_key;
      // await this.get_article(data);

      this.article = this.article_data;
      this.article_hero = this.article_data.article_hero_image;
    } catch (errs) {
      if (!this.article) {
        router.push(`/${this.lang_info.lang_code}/page_not_found`);
      }
    }

    document.getElementById(
      "currentURL"
    ).value = `${window.location.href}?source=share`;
  },
  methods: {
    ...mapActions("content", ["get_article"]),
    ...mapActions("language", ["getLangInfo", "getLangStanza"]),
    langLookup(key) {
      if (!key) {
        return null;
      }
      let lang_value = this.lang_stanza[key];
      if (!lang_value) {
        lang_value = key;
      }
      return lang_value;
    },
    copyText() {
      let copyText = document.getElementById("currentURL");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(copyText.value);
      let linkText = document.getElementById("linkText");
      linkText.innerHTML = "Link copied!";
      linkText.style.fontWeight = "bold";
      linkText.style.textDecoration = "none";
    },
    openShareLink() {
      let copyUrl = document.getElementById("copy-url");
      if (copyUrl.style.display === "none") {
        copyUrl.style.display = "block";
      } else {
        copyUrl.style.display = "none";
      }
    },
    closeShare() {
      let copyUrl = document.getElementById("copy-url");
      if (copyUrl.style.display === "block") {
        copyUrl.style.display = "none";
      }
    },
  },
  computed: {
    ...mapState("content", ["downloads", "article_data"]),
  },
};
</script>

<style lang="scss" scoped>
.grade-flag {
  display: flex;
  flex-direction: column;
}

.grade-flag p {
  width: 119px;
  height: 40px;
  border-radius: 16px 0px;
  padding: 10px 0;
  color: #000;
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  justify-self: center;
  margin-bottom: 0;
}

.Eighth {
  background-color: #f57f54;
}
.Ninth {
  background-color: #f6c569;
}
.Tenth {
  background-color: #35b888;
}
.Eleventh {
  background-color: #369fe1;
}
.Twelfth {
  background-color: #af709e;
}

.blue-background {
  background-color: #fff;
}

.article-page-header {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: auto;
  margin-bottom: 30px;
  margin-top: 104px;
  max-width: 636px;

  > div {
    max-width: 636px;
  }
}

.article-header {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;
  margin: 18px 10px
}

.title-underline {
  height: 8px;
  width: 256px;
  background-color: #F57F54;
}

#time-link-line {
  display: flex;
  margin-top: 18px;
  align-items: center;
}

.share-link {
  display: flex;
  color: #000;
  font-family: "Open Sans";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.share-link:hover {
  cursor: pointer;
}

.share-link > img {
  margin-right: 4px;
  color: #f57f54;
}

#copy-url {
  color: #3a3a3a;
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  position: relative;
  bottom: 22px;
  left: 0px;
  margin-bottom: -108px;
  padding-left: 14px;
  height: 150px;
  width: 695px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 5px 15px 1px rgba(122, 122, 122, 0.3);
}

.copy-url-text {
  float: left;
  margin-top: 11px;
  margin-left: 3px;
}

.close-share-icon {
  position: relative;
  top: 5px;
  left: 230px;
}

input#currentURL {
  background: #eee;
  color: #666666;
  height: 41px;
  width: 95%;
  border: 1px solid #7a7a7a;
  border-radius: 4px;
  position: relative;
  top: 10px;
}

#copy-url button {
  height: 40px;
  width: 119px;
  border-radius: 16px;
  background-color: #274579;
  color: #ffffff;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  border: none;
  position: relative;
  bottom: -20px;
  left: 0px;
}

.img-div {
  > img {
    max-width: 880px;
  }
}

.article-img {
  max-width: 880px;
}

.time-estimate {
  color: #3a3a3a;
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: left;
}

.share-link {
  margin-left: auto;
  margin-right: 18px;
}

#article-container {
  margin: auto;
  max-width: 656px;
  text-align: left;
}

.at-a-glance > h2 {
  color: #030303;
  font-family: Lato;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 48px;
}

.at-a-glance p {
  color: #3a3a3a;
  font-family: Lora;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28px;
}

.at-a-glance {
  text-align: left;
}

.dynamic-content {
  display: flex;
  margin: auto;
  margin-top: 32px;
  text-align: left;
  max-width: 656px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
}

.article-references {
  font-family: Lora;
  font-size: 18px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 28px;
  margin-top: 32px;
}

.article-references > b {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}

.article-references > div {
  margin-top: 16px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #1d93dd;

  > div:last-of-type {
    margin-bottom: 24px;
  }
}

.article-references a:hover {
  color: #1a6edb;
}

.scroll-top-btn {
  padding: 0;
  display: flex;
  color: #000;
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  align-items: center;
  justify-content: center;
}

.scroll-top-btn > img {
  margin-left: 8px;
  height: 24px;
  width: 24px;
}

.scroll-top-btn-art > img {
  margin-left: 8px;
}

#pathway-header {
  padding: 0px 0px 20px 0px;
}

#strategy {
  padding-bottom: 20px;
  text-decoration: underline;
}

.learn-more-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 32px;
  padding: 16px 48px;
  gap: 8px;
  width: 193px;
  height: 60px;
  background: #c24c21;
  border: 2px solid #c24c21;
  border-radius: 16px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.learn-more-btn:hover {
  background-color: #FFF;
  color: #c24c21;
}

#linkText {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #030303;
}

@media (max-width: 900px) {
  .img-div > img {
    max-width: 100%;
  }

  .article-img {
    max-width: 100%;
  }
}

@media (max-width: 700px) {
  .scroll-top-btn-art {
    top: 25px;
    left: -40px;
  }
  .img-div {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .article-img {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .article-header {
    font-family: "Merriweather";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
  }

  .article-page-header {
    padding-left: 0px;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 30px;
  }

  .title-underline {
    min-width: 256px;
  }

  .time-estimate {
    margin: 0;
    margin-left: 18px;
  }

  .share-link {
    margin-left: auto;
    margin-right: 18px;
  }

  #article-container {
    margin-right: 0px;
    margin-left: 0px;
  }

  .at-a-glance > h2 {
    margin-left: 15px;
  }

  .at-a-glance {
    padding-left: 0px;
    padding-right: 0px;
    max-width: 90%;
  }

  .dynamic-content {
    margin-left: 8px;
    margin-right: 8px;
  }
}

@media (max-width: 400px) {
  .title-underline {
    width: 100%;
    margin: auto;
  }

  .learn-more-btn {
    margin-top: 16px;
  }
}
</style>


<template>
  <div>
    <Header>
      <GTM></GTM>
    </Header>

    <div id="articles-content" v-if="article">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link
              variant="link"
              :to="{ path: `/${lang_info.lang_code}/home` }"
              >{{ lang_stanza.home_page }}<i class="arrow right"></i
            ></router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link variant="link" :to="`${bc_path}`">
              <i class="arrow right"></i> {{ breadcrumb_grade_pathway }}
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ article_data.article_title }}
          </li>
        </ol>
      </nav>
      <div v-if="article_data.article_external">
        <!-- {{ article_data.article_external }} -->
        <ArticleExternal></ArticleExternal>
      </div>
      <div v-else>
        <Article></Article>
      </div>
    </div>
    <div class="article-footer">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Article from "../components/Article.vue";
import ArticleExternal from "../components/ArticleExternal.vue";
import { mapActions, mapState } from "vuex";
import { router, sessionStorage } from "../_helpers";

export default {
  name: "Articles",
  data() {
    return {
      grade_pathway: {},
      article: {},
      breadcrumb_grade_pathway: "",
      bc_path: "",
      lang_stanza_key: "article",
      lang_stanza: {},
      lang_info: {},
    };
  },
  async created() {
    this.lang_info = await this.getLangInfo();
    this.lang_stanza = await this.getLangStanza({
      lang_code: this.lang_info["lang_code"],
      lang_stanza_key: this.lang_stanza_key,
    });

    try {
      let data = {};

      data.article_key = this.$route.params.article_key;
      data.lang_code = this.lang_info.lang_code;

      await this.get_article(data);

      this.article = this.article_data;

      this.breadcrumb_grade_pathway = `${
        this.article_data.grade_key_suffix
      } Grade ${this.article_data.pathway_title
        .charAt(0)
        .toUpperCase()}${this.article_data.pathway_title.slice(1)} Action Plan`;

      this.bc_path = await this.isLoggedIn();
    } catch (errs) {
      this.errors = this.langLookup("err_not_found");
    }
  },
  components: {
    ArticleExternal,
    Article,
  },
  methods: {
    ...mapActions("content", ["get_article"]),
    ...mapActions("language", ["getLangInfo", "getLangStanza"]),
    langLookup(key) {
      if (!key) {
        return null;
      }
      let lang_value = this.lang_stanza[key];
      if (!lang_value) {
        lang_value = key;
      }
      return lang_value;
    },
    async isLoggedIn() {
      let sessionUserProfile = await sessionStorage.decodeUserSession();
      if (!sessionUserProfile || !sessionUserProfile.sub) {
        return `/${this.lang_info.lang_code}/login`;
      }
      return `/${this.lang_info.lang_code}/action-plan/${this.article_data.grade_key}/${this.article_data.pathway_key}`;
    },
  },
  computed: {
    ...mapState("content", ["downloads", "article_data"]),
  },
};
</script>

<style lang="scss" scoped>
#articles-content nav {
  height: 0px;
  background-color: #E1E1E1 !important;
}

.breadcrumb {
  position: relative;
  // bottom: 52px;
  background: #ffffff;
  // border-top: 0.5px solid #e6e9ed;
  padding: 20px 0px 20px 98px;
  margin-bottom: 0;
}

.breadcrumb-item {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #047AC3;
}

.breadcrumb-item::before {
  display: none;
}

.arrow {
  border: solid #047AC3;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4.7px;
  border-radius: 1.7px;
  margin-right: 10px;
  margin-left: 11px;
}

.right {
  transform: rotate(0deg);
  -webkit-transform: rotate(135deg);
}

.breadcrumb-item > a {
  color: #1a6edb !important;
}

.breadcrumb li:nth-child(1) {
  display: none;
}

.breadcrumb li:nth-child(3) {
  display: none;
}

#article-container {
  border: 1px solid gray;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

#article-header {
  font-weight: bold;
  font-size: 36px;
  text-align: left;
  padding: 5px 5px 5px 30px;
  color: #fff;
  background: rgb(85, 85, 194);
}

#article-subheader {
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  padding: 5px 5px 5px 30px;
  background: rgb(203, 230, 255);
}

#article-body {
  max-width: 100%;
  padding-right: 5rem;
}

.article-footer {
  // margin-top: 145px;
}

// @media (min-width: 250px) and (max-width: 499px) {
//   .breadcrumb {
//     padding: 32px 0px 20px 20px;
//     // margin-top: 50px;
//   }

//   .arrow {
//     margin-left: -10px;
//   }
// }

// @media (min-width: 500px) and (max-width: 600px) {
//   .breadcrumb {
//     padding: 32px 0px 20px 20px;
//     // margin-top: 50px;
//   }

//   .arrow {
//     margin-left: -10px;
//   }
// }

// @media (min-width: 601px) and (max-width: 768px) {
//   .breadcrumb {
//     padding: 32px 0px 20px 20px;
//     // margin-top: 50px;
//   }

//   .arrow {
//     margin-left: -10px;
//   }
// }

@media (min-width: 769px) and (max-width: 900px) {
  .arrow {
    margin-left: -70px;
  }

  .img-div {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .article-img {
    flex-shrink: 0;
    min-width: 100%;
    max-height: 100%;
  }

  #copy-url {
    color: #3a3a3a;
    font-family: Lato;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
    position: relative;
    bottom: 22px;
    left: 15px;
    margin-bottom: -108px;
    padding-left: 14px;
    height: 150px;
    width: 350px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 5px 15px 1px rgba(122, 122, 122, 0.3);
  }

  input#currentURL {
    color: #666666;
    height: 41px;
    width: 100%;
    border: 1px solid #7a7a7a;
    border-radius: 4px;
    position: relative;
    top: 17px;
    right: 15px;
    margin-left: 5px;
  }

  #copy-url button {
    height: 40px;
    width: 119px;
    border-radius: 16px;
    background-color: #274579;
    color: #ffffff;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    border: none;
    position: relative;
    bottom: -30px;
    left: 0px;
  }

  .copy-url-text {
    float: left;
    margin-top: 11px;
    margin-left: -10px;
  }

  .close-share-icon {
    position: relative;
    top: 5px;
    left: 75px;
  }
  
  .breadcrumb {
    width: 100%;
  }

}

@media (max-width: 991px) {
  .breadcrumb {
    padding: 20px 0px 20px 35px;
    // margin-top: 50px;
    overflow: hidden;
  }
  .breadcrumb-item > a{
    white-space: nowrap;
  }
  .arrow {
    margin-left: -10px;
  }
}
</style>

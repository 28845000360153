<template>
  <div id="article-content">
    <div class="blue-background">
      <div class="article-page-header" v-if="article_data">
        <div>
          <div class="grade-flag">
            <!--
        <p :class="switchClass(milestone.grade_key)">
          {{ milestone.grade_text_suffix }} Grade
        </p>
        -->
            <p :class="`${article_data.grade_text_suffix}`">
              {{ article_data.grade_title }}
            </p>
          </div>
          <h1 class="article-header">
            {{ article_data.article_title }}
          </h1>
          <div class="title-underline"></div>
          <div id="time-link-line">
            <p class="time-estimate">
              {{ article_data.article_content.time_estimate }}
            </p>
            <div class="share-link" id="share-link" @click="copyText">
              <img src="../assets/link-icon.png" alt="" />
              <div id="linkText">Copy link</div>
            </div>
          </div>
          <div id="copy-url" style="display: none">
            <div class="copy-url-text">Copy this URL to share:</div>
            <label @click="closeShare" class="close-share">
              <img
                class="close-share-icon"
                src="../assets/close-icon.svg"
                alt="close share link icon"
              />
            </label>
            <input type="text" id="currentURL" />
            <button @click="copyText">Copy</button>
          </div>
        </div>
      </div>
      <div class="img-div" v-if="article_data.article_hero_image">
        <img
          :src="article_data.article_hero_image"
          alt="Article hero image"
          class="article-img"
        />
      </div>
      <div v-else-if="article_data.category_hero_image">
         <img
          :src="article_data.category_hero_image"
          alt="Category hero image"
          class="article-img"
        />
      </div>
      <div v-else>
        <img
          src="../assets/Article_forest.jpg"
          alt="Article hero image"
          class="article-img"
        />
      </div>
      <!-- <div v-if="article_data.article_content">
        <div
          class="at-a-glance"
          v-if="article_data.article_content.article_at_a_glance"
        >
          <h2>
            {{
              article_data.article_content.article_at_a_glance
                .article_at_a_glance_title
            }}
          </h2>
          <p
            v-html="
              article_data.article_content.article_at_a_glance
                .article_at_a_glance_body
            "
          >
            {{
              article_data.article_content.article_at_a_glance
                .article_at_a_glance_body
            }}
          </p>
        </div>
      </div> -->
    </div>
    <div id="article-container">
      <div v-if="errors">
        <span v-html="errors"></span>
      </div>
      <div class="dynamic-content" v-html="article_content.article_body" />
      <div class="article-references" v-if="article_content.article_references">
        <b>References</b>
        <br />
        <div
          v-for="(article_reference, ar_idx) in article_data.article_content
            .article_references.article_reference_list"
          :key="ar_idx"
        >
          <a
            :href="`${article_reference.article_reference_url}`"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ article_reference.article_reference_header }}
          </a>
          <!-- <br />article_reference_url_text:
          {{ article_reference.article_reference_url_text }} -->
        </div>
      </div>
      <!-- <p /> -->
      <!-- <br />article_tags: {{ article_data.article_content.article_tags }}
  
      <br />grade_key: {{ article_data.grade_key }} <br />grade_key_suffix:
      {{ article_data.grade_key_suffix }} <br />grade_text:
      {{ article_data.grade_text }} <br />grade_text_suffix:
      {{ article_data.grade_text_suffix }} <br />grade_title:
      {{ article_data.grade_title }} <br />pathway_key:
      {{ article_data.pathway_key }} <br />pathway_title:
      {{ article_data.pathway_title }} <br />grade_pathway_title:
      {{ article_data.grade_pathway_title }} <br />milestone_key:
      {{ article_data.milestone_key }} <br />milestone_title:
      {{ article_data.milestone_title }} <br />strategy_key:
      {{ article_data.strategy_key }} <br />strategy_title:
      {{ article.strategy_title }}
    </div>

    <div v-if="article_data.article_external">
      <br />----- <br /><strong>Article - External</strong> <br />article_title:
      {{ article_data.article_title }} <br />article_key:
      {{ article_data.article_key }} <br />article_h_image:
      {{ article_data.article_h_image }} <br />article_description:
      <div
        class="dynamic-content"
        v-html="article_data.article_external.article_description"
      />
      <br />article_external_url:
      {{ article_data.article_external.article_external_url }}
      <br />article_external_url_text:
      {{ article_data.article_external.article_external_url }}
      <br />grade_key: {{ article_data.grade_key }} <br />grade_key_suffix:
      {{ article_data.grade_key_suffix }} <br />grade_text:
      {{ article_data.grade_text }} <br />grade_text_suffix:
      {{ article_data.grade_text_suffix }} <br />grade_title:
      {{ article_data.grade_title }} <br />pathway_key:
      {{ article_data.pathway_key }} <br />pathway_title:
      {{ article_data.pathway_title }} <br />grade_pathway_title:
      {{ article_data.grade_pathway_title }} <br />milestone_key:
      {{ article_data.milestone_key }} <br />milestone_title:
      {{ article_data.milestone_title }} <br />strategy_key:
      {{ article_data.strategy_key }} <br />strategy_title:
      {{ article_data.strategy_title }} -->
      <div id="button-area">
        <button
          type="button"
          class="scroll-top-btn-art bg-transparent border-0"
          id="scroll-top-btn"
        >
          <img src="../assets/orange_up_arrow.svg" alt="arrow up button" />
          {{ lang_stanza.back_to_top }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// import parser from "fast-xml-parser";
import { mapActions, mapState } from "vuex";
import { router } from "../_helpers";

export default {
  name: "Article",
  data() {
    return {
      title: "",
      description: "",
      errors: "",
      article: {},
      article_content: {},
      article_external: {},
      lang_info: {},
      lang_stanza_key: "article",
      lang_stanza: {},
    };
  },
  async created() {
    try {
      this.lang_info = await this.getLangInfo();
      this.lang_stanza = await this.getLangStanza({
        lang_code: this.lang_info["lang_code"],
        lang_stanza_key: this.lang_stanza_key,
      });

      let data = {};
      data.article_key = this.$route.params.article_key;
      data.lang_code = this.lang_info.lang_code;
      await this.get_article(data);

      this.article = this.article_data;
      this.article_content = this.article_data.article_content;
      this.article_external = this.article_data.article_external;
      //this.article_hero = this.article_data.article_hero_image;
    } catch (errs) {
      //this.errors = this.lang_stanza["err_not_found"];
      router.push(`/${this.lang_info.lang_code}/page_not_found`);
    }
    document.body.style.backgroundColor = "#FFFFFF";
    document.title = this.article_data.article_title;
    window.addEventListener("scroll", this.scrollUp);
    document.getElementById(
      "currentURL"
    ).value = `${window.location.href}?source=share`;
    // document.getElementById("share-link").onclick = function () {
    //   document.getElementById("copy-url").style.display = "block";
    // };

  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    ...mapActions("content", ["get_article"]),
    ...mapActions("language", ["getLangInfo", "getLangStanza"]),
    scrollUp() {
      let scrollBtn = document.getElementById("scroll-top-btn");
      if (scrollBtn) {
        scrollBtn.addEventListener("click", this.backToTop);
        if (
          document.body.scrollTop > 20 ||
          document.documentElement.scrollTop > 20
        ) {
          scrollBtn.style.display = "block";
        } else {
          scrollBtn.style.display = "none";
        }
      }
    },
    backToTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    copyText() {
      let copyText = document.getElementById("currentURL");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(copyText.value);
      let linkText = document.getElementById("linkText");
      linkText.innerHTML = "Link copied!";
      linkText.style.fontWeight = "bold";
      linkText.style.textDecoration = "none";
    },
    openShareLink() {
      let copyUrl = document.getElementById("copy-url");
      if (copyUrl.style.display === "none") {
        copyUrl.style.display = "block";
      } else {
        copyUrl.style.display = "none";
      }
    },
    closeShare() {
      let copyUrl = document.getElementById("copy-url");
      if (copyUrl.style.display === "block") {
        copyUrl.style.display = "none";
      }
    },
  },
  computed: {
    ...mapState("content", ["downloads", "article_data"]),
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollUp);
  },
};
</script>

<style lang="scss" scoped>
.grade-flag {
  display: flex;
  flex-direction: column;
}

.grade-flag p {
  width: 119px;
  height: 40px;
  border-radius: 16px 0px;
  padding: 10px 0;
  color: #000;
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  justify-self: center;
  margin-bottom: 0;
}

.Eighth {
  background-color: #f57f54;
}
.Ninth {
  background-color: #f6c569;
}
.Tenth {
  background-color: #35b888;
}
.Eleventh {
  background-color: #369fe1;
}
.Twelfth {
  background-color: #af709e;
}

.blue-background {
  background-color: #fff;
}

.article-page-header {
  display: flex;
  justify-content: center;
  text-align: left;
  margin-bottom: 30px;
  margin-top: 104px;
  margin-left: 8px;
  margin-right: 8px;
  > div {
    width: 656px;
    max-width: 636px;
  }
}

.article-header {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;
  margin: 18px 10px
}

.title-underline {
  margin-top: 18px;
  height: 8px;
  width: 256px;
  background-color: #F57F54;
}

#time-link-line {
  display: flex;
  margin-top: 18px;
  align-items: center;
}

.share-link {
  display: flex;
  color: #000;
  font-family: "Open Sans";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.share-link:hover {
  cursor: pointer;
}

.share-link > img {
  margin-right: 4px;
  color: #F57F54;
}

// #copy-url {
//   color: #3a3a3a;
//   font-family: Lato;
//   font-size: 16px;
//   letter-spacing: 0;
//   line-height: 19px;
//   position: relative;
//   bottom: 22px;
//   left: 0px;
//   margin-bottom: -108px;
//   padding-left: 14px;
//   height: 150px;
//   width: 695px;
//   border-radius: 10px;
//   background-color: #ffffff;
//   box-shadow: 0 5px 15px 1px rgba(122, 122, 122, 0.3);
// }

.copy-url-text {
  float: left;
  margin-top: 11px;
  margin-left: 3px;
}

.close-share-icon {
  position: relative;
  top: 5px;
  left: 230px;
}

input#currentURL {
  background: #eee;
  color: #666666;
  height: 41px;
  width: 95%;
  border: 1px solid #7a7a7a;
  border-radius: 4px;
  position: relative;
  top: 10px;
}

#copy-url button {
  height: 40px;
  width: 119px;
  border-radius: 16px;
  background-color: #274579;
  color: #ffffff;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  border: none;
  position: relative;
  bottom: -20px;
  left: 0px;
}

.img-div {
  > img {
    max-width: 880px;
  }
}

.time-estimate {
  color: #3a3a3a;
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: left;
  margin-bottom: 0;
}

.share-link {
  margin-left: auto;
  margin-right: 18px;
}

#linkText {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #030303;
}

#article-container {
  margin: auto;
  max-width: 656px;
  text-align: left;
}

.at-a-glance > h2 {
  color: #030303;
  font-family: Lato;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 48px;
}

.at-a-glance p {
  color: #3a3a3a;
  font-family: Lora;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28px;
}

.at-a-glance {
  text-align: left;
}

.article-references {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  color: #000000;
  margin-top: 32px;
}

.article-references > b {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
}

.article-references > div {
  margin-top: 16px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #1D93DD;

  > div:last-of-type {
    margin-bottom: 24px;
  }
}

.article-references a:hover {
  color: #1a6edb;
}

.scroll-top-btn {
  padding: 0;
  display: flex;
  color: #000;
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  align-items: center;
  justify-content: center;
}

.scroll-top-btn > img {
  margin-left: 8px;
  height: 24px;
  width: 24px;
}

.scroll-top-btn-art > img {
  margin-left: 8px;
}

#pathway-header {
  padding: 0px 0px 20px 0px;
}

#strategy {
  padding-bottom: 20px;
  text-decoration: underline;
}

#button-area {
  max-width: 656px;
  margin: auto;
  margin-top: 24px;
  display: flex;
  align-content: flex-end;
  align-items: end;
  justify-content: right;
  text-decoration: underline;
  text-decoration-thickness: 0.5px;

  > button {
    display: flex !important;
    align-items: center;
  }
}
</style>

<style scoped>
.dynamic-content {
  margin-top: 32px;
}

.dynamic-content >>> h1 {
  color: red;
  font-size: 14pt;
}

.dynamic-content >>> h2 {
  color: #030303;
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  margin-bottom: 16px;
  margin-top: 32px;
}

.dynamic-content >>> h3 {
  color: #3a3a3a;
  font-family: Lato;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
}

.dynamic-content >>> li {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  margin-right: 8px;
  color: #000000;
}

.dynamic-content >>> a {
  color: #1a6edb !important;
}

.dynamic-content >>> li > a {
  color: #1a6edb !important;
}

.dynamic-content >>> li > a:hover {
  color: #1a6edb !important;
}

.dynamic-content >>> p {
  color: rgba(0, 0, 0, 0.85);
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  margin-top: 18px;
}

.dynamic-content > p:last-of-type {
  margin-bottom: 0;
}

.dynamic-content >>> td {
  font-size: 10pt;
  background-color: yellow;
  border: 1px solid red;
}

@media (max-width: 900px) {
  .img-div > img {
    max-width: 100%;
  }
}

@media (max-width: 700px) {
  .scroll-top-btn-art {
    top: 25px;
    left: -40px;
  }
  .img-div {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .article-img {
    flex-shrink: 0;
    /* min-width: 100%; */
    min-height: 230px;
  }

  .article-header {
    font-family: "Merriweather";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
  }

  .article-page-header {
    padding-left: 0px;
    margin-bottom: 30px;
  }

  .title-underline {
    min-width: 256px;
  }

  .time-estimate {
    margin: 0;
    margin-left: 18px;
  }

  .share-link {
    margin-left: auto;
    margin-right: 18px;
  }

  #article-container {
    margin-right: 0px;
    margin-left: 0px;
  }

  .at-a-glance > h2 {
    margin-left: 15px;
  }

  .at-a-glance {
    padding-left: 0px;
    padding-right: 0px;
    max-width: 90%;
  }

  .dynamic-content >>> h2 {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
    margin-bottom: 16px;
    margin-top: 32px;
    margin-left: 8px;
    max-width: 90%;
  }

  .dynamic-content >>> li {
    font-size: 16px;
  }

  .dynamic-content >>> a {
    color: #1a6edb !important;
  }

  .dynamic-content >>> li > a {
    color: #1a6edb !important;
  }

  .dynamic-content >>> li > a:hover {
    color: #1a6edb !important;
  }

  .dynamic-content >>> p {
    color: rgba(0, 0, 0, 0.85);
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    max-width: 90%;
    margin-left: 8px;
    margin-right: 8px;
  }

  .dynamic-content >>> td {
    font-size: 10pt;
    background-color: yellow;
    border: 1px solid red;
  }

  .article-references {
    max-width: 90%;
    margin-left: 8px;
  }

  .article-references > b {
    font-size: 24px;
  }

  .article-references > div {
    font-size: 16px;
  }

  #copy-url {
    left: 15px;
    width: 350px;
  }

  input#currentURL {
    top: 17px;
    right: 15px;
    margin-left: 5px;
  }

  #copy-url button {
    bottom: -30px;
  }

  .copy-url-text {
    margin-left: -10px;
  }

  .close-share-icon {
    left: 75px;
  }

  #button-area {
    margin-top: 33px;
  }

}

@media (max-width: 400px) {
  .title-underline {
    width: 100%;
    margin: auto;
  }
}
</style>


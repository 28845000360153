<template>
  <div>
    <Header>
      <GTM></GTM>
    </Header>
    <div style="text-align:left;">
    <div v-for="(tag_info, tag) in tagged_article_dict" :key="tag">
      <div style="margin: 1rem 0 0 1rem;">{{tag_info.tag_title}}</div>
      <div v-for="article in tag_info.article_list" :key="article.article_key">
        <div style="margin: 1rem 0 0 5rem;"><em>{{article.article_title}}</em></div>
        <div style="margin: 0 0 0 7rem;"><a target="_blank" :href="`/${lang_info.lang_code}/article/${article.article_key}`">{{ article.article_key }}</a></div>
      </div>
    </div>
  </div>
    <Footer></Footer>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "Articles",
  data() {
    return {
      lang_stanza_key: 'article',
      lang_stanza: {}, 
      lang_info: {}
    };
  },
  async created() {

    this.lang_info = await this.getLangInfo();
    this.lang_stanza = await this.getLangStanza({
      "lang_code": this.lang_info["lang_code"],
      "lang_stanza_key": this.lang_stanza_key
    });

    let error_list;
    let tag = this.$route.params.tag;
    let tag_list = [tag];
    let param_dict = {"tag_list": tag_list};
    error_list = await this.get_tagged_articles(param_dict);
  },
  methods: {
    ...mapActions('content', ['get_tagged_articles']),
    ...mapActions('language', ['getLangInfo', 'getLangStanza'])
  },
  computed: {
    ...mapState('content', ['tagged_article_dict']),
  },
};
</script>

<style lang="scss" scoped>

#articles-content nav {
  height: 0px;
}

.breadcrumb {
  position: relative;
  // bottom: 52px;
  background: #ffffff;
  // border-top: 0.5px solid #E6E9ED;
  padding: 32px 0px 32px 106px;
}

.breadcrumb-item {
  color: #1a6edb;
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
}

.breadcrumb-item::before {
  display: none;
}

.arrow {
  border: solid #666666;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4.7px;
  border-radius: 1.7px;
  margin-right: 10px;
  margin-left: 11px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.breadcrumb-item > a {
  color: #1a6edb !important;
}

#article-container {
  border: 1px solid gray;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

#article-header {
  font-weight: bold;
  font-size: 36px;
  text-align: left;
  padding: 5px 5px 5px 30px;
  color: #fff;
  background: rgb(85, 85, 194);
}

#article-subheader {
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  padding: 5px 5px 5px 30px;
  background: rgb(203, 230, 255);
}

#article-body {
  max-width: 100%;
  padding-right: 5rem;
}
</style>
